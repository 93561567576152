.App {
  background-color: rgb(19, 28, 54);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

main {
  max-width: 400px;
  margin: auto;
  padding: 10px 4px;
}

form {
  padding: 0 4px;
  text-align: left;
  margin-bottom: 10px;
}

form > input[type=text] {
  width: 100%;
  min-height: 48px;
  margin: 4px 0;
  box-sizing: border-box;
  border-radius: 4px;
}

form > label {
  width: min(60px, 60px);
}

form > button, form > a > button {
  width: 100%;
  min-height: 48px;
}

.user-icon {
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: auto;
  margin-left: auto;
  image-rendering: pixelated;
  min-width: 32px;
}

.btn-group {
  display: flex;
  width: 100%;
  min-height: 48px;
  border-radius: 15px;
  justify-content: center;
}

.btn-group > a {
  all: unset;
}

.btn-group > button, .btn-group > a {
  flex: auto;
  border-color: transparent;
}

.btn-group > button[disabled] {
  background-color: darkgray;
}

.btn-group > button:first-child, .btn-group > a:first-child > button {
  border-radius: 8px 0 0 8px;
}

.btn-group > button:last-child, .btn-group > a:last-child > button {
  border-radius: 0 8px 8px 0;
}

a.btnt {
  padding: 1px 6px;
  min-height: 48px;
  background-color: lightgray;
  font-size:initial;
  text-align: center;

}

.btn-group > a > button {
  width: 100%;
  min-height: 48px;
  padding: 0;
  margin: 0;
  border: 0;
}

.errorMsg {
  padding: 3px 10px;
  border: solid 1px #dc3545;
  border-radius: 10px;
  background-color: #dc3545;
}

/*
 Item-Stats table CSS
*/
.item-icon {
  max-width: 16px;
  max-height: 16px;
  width: auto;
  height: auto;
  vertical-align: middle;
}

.item-stat-table {
  max-width: 700px;
  border-collapse: collapse;
}

/*
.item-stat-table > tbody > tr, .item-stat-table > thead > tr {
  display: table-row
}
*/

.item-stat-table > tbody > tr > td:first-child, .item-stat-table > thead > tr > th:first-child {
  overflow: visible;
}

.item-stat-table > tbody > tr > td:not(:first-child), .item-stat-table > thead > tr > th:not(:first-child) {
  white-space: nowrap;
}

.item-stat {
  border-top: solid 1px darkgray;
}

.item-stat:nth-child(2n) {
  color: gray;
}

.item-stat:nth-child(2n + 1) {
  color: white;
}

.item-stat > td:first-child {
  text-align: left;
  padding-left: 5px;
}

.item-stat > td {
  text-align: right;
  padding-right: 5px;
}

th > button {
  all: unset;
}

/*
 Custom-Stats list CSS
*/
.custom-stat-list {
  padding: unset;
  margin: unset;
  list-style: none;
}

.custom-stat-list > .custom-stat:nth-child(2n) {
  color: gray;
}

.custom-stat-list > .custom-stat:nth-child(2n + 1) {
  color: white;
}

.custom-stat-value {
  float: right;
}

/*
 Mob-Stats list CSS
*/
.mob-stat-list {
  padding: unset;
  margin: unset;
  list-style: none;
}

.mob-stat {
  margin-top: 5px;
}

.mob-stat > p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.mob-stat > p:not(:first-child) {
  padding-left: 15px;
}